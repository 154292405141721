import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BrandingColors } from '@repo/shared';

@Component({
	selector: 'app-branding',
	templateUrl: './branding.component.html',
	styleUrl: './branding.component.scss',
})
export class BrandingComponent {
	@Input({ required: true }) branding!: BrandingColors;
	@Input() disabled = false;

	@Output() brandingChanged = new EventEmitter<BrandingColors>();
	@Output() brandingCleared = new EventEmitter();
}
