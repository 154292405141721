import { DocumentReference, Timestamp } from 'firebase/firestore';
import { FirestoreDocument } from './generalModels';
import { VariablesData5 } from './variableModels';
import { OrganizationId } from './organizationModels';
import { GeneralInfo } from '@repo/shared';

/////////////////////////
// User Models
/////////////////////////

export const userCollectionsToIdPrefixMapping: { [key: string]: string } = {
	users: 'user_',
	// "userEmails": "", // the email is the id
};

export type User5 = FirestoreDocument & {
	// within users/{userId}
	userRef: DocumentReference | null;
	userId: string;
	created: Timestamp | null;
	info: UserInfo5; // name should be retreived from the firebase auth user Display Name
	isAnonymous: boolean;

	data?: UserData5;
};

export type UserData5 = {
	memberships?: UserMembershipsData5;
	variables?: VariablesData5;
	templates?: UserTemplatesData5;
	language?: 'en' | 'nl';
};

export type UserMembershipsData5 = {
	active?: {
		organizationId?: OrganizationId;
		timestamp?: number;
	};
};

export type UserTemplatesData5 = {
	[organizationId: OrganizationId]: UserOrganizationTemplatesData5;
};

export type UserOrganizationTemplatesData5 = {
	start: string | null;
	pinned: string[];
};

export type UserEmail5 = FirestoreDocument & {
	// within users/{userId}/userEmails/{email}
	email: string;
	verified: boolean;
	userRef: DocumentReference;
	providerIds: string[];
};

export type UserInfo5 = GeneralInfo;
