import { BrandingColors } from '../branding';
import { Contact, GeneralInfo } from '../general';
import { Nullable } from '../common';

export * from './permissions';
export * from './roles';

export type OrganizationBranding = {
	colors: BrandingColors;
	logo: string | null;
};

export type OrganizationInfo = GeneralInfo & {
	website: Nullable<string>;
	contact: Nullable<Contact>;
};
