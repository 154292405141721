import { UserService } from '../../../../../user/services/user.service';
import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { UserInfo5 } from '../../../models/database/userModels';
import { Subscription } from 'rxjs';
import { SharedDocumentReference } from '@repo/shared';

@Component({
	selector: 'app-user-badge',
	templateUrl: './user-badge.component.html',
	styleUrls: ['./user-badge.component.scss'],
})
export class UserBadgeComponent implements OnChanges, OnDestroy {
	@Input() userInfo: UserInfo5 | undefined | null;
	@Input() userRef: SharedDocumentReference | undefined | null;
	@Input() showName: boolean = true;
	@Input() hideBackground: boolean = false;

	protected isLoggedInUser: boolean = false;
	private subscription: Subscription | undefined;

	constructor(public userService: UserService) {
		this.subscription = userService.user.subscribe(() => {
			this.update();
		});
	}

	ngOnChanges(): void {
		this.update();
	}

	ngOnDestroy(): void {
		this.unsubscribe();
	}

	private update() {
		if (!this.userRef) return;
		this.isLoggedInUser = this.userRef?.path === this.userService.user.value?.docRef?.path;
	}

	private unsubscribe() {
		if (this.subscription) {
			this.subscription.unsubscribe();
			this.subscription = undefined;
		}
	}
}
