import { SharedDocumentReference } from './internal';
import { Nullable } from './common';
import { MetaData } from './general';

export enum WalletCreditChangeReason {
	GIFT = 'gift',
	PURCHASE = 'purchase',
	PURCHASE_FAILED = 'purchaseFailed',
	REFUND = 'refund',
	AI_USAGE = 'aiUsage',
	SYSTEM = 'system',
}

// this type is extended by the FirestoreDocument in the apps.
export type WalletCreditChangeBase = {
	amount: number;
	reason: WalletCreditChangeReason;
	contextRef: Nullable<SharedDocumentReference>;
	contextRtdbRef: Nullable<string>;
	created: MetaData; // includes userId and organizationId
	stripeInvoiceId?: string;
	stripeInvoicePdfUrl?: string;
	stripeInvoicePageUrl?: string;
};

export type WalletCreditDataBase = {
	credits: number;
	minCredits: number;
};

export enum WalletStatus {
	ACTIVE = 'active',
	BLOCKED = 'blocked',
	INACTIVE = 'inactive',
}

export enum WalletOwnerType {
	ORG = 'org',
	USER = 'user',
}
