import { tokenCollectionsToIdPrefixMapping } from './publicTokenModels';
import { DocumentReference } from 'firebase/firestore';
import { OrganizationDocType5, organzationCollectionsToIdPrefixMapping } from './organizationModels';
import { userCollectionsToIdPrefixMapping } from './userModels';
import { inviteCollectionsToIdPrefixMapping } from './inviteModels';
import { SharedDocumentReference, Tag } from '@repo/shared';

/////////////////////////
// General Models
/////////////////////////

export type DatabaseObject = Tag<object, 'DatabaseObject'>;

// cloud functions BaseModel
// export type FirestoreDocument = {};

// (un)conmment for Angular client
export type FirestoreDocument<T extends string = string> = DatabaseObject & {
	docRef?: SharedDocumentReference;
	docId?: T;
};

export const idPrefixes: { [key: string]: string } = {
	...userCollectionsToIdPrefixMapping,
	...organzationCollectionsToIdPrefixMapping,
	...tokenCollectionsToIdPrefixMapping,
	...inviteCollectionsToIdPrefixMapping,
};

export type Reference5 = {
	ref?: DocumentReference;
	id: string;
};

export type DataDoc5 = FirestoreDocument & {
	doc: Doc5;
};

export type Doc5 = {
	name: string;
	type: DocType5;
	ownerRef: DocumentReference | null;
};

export type DocType5 = OrganizationDocType5;
