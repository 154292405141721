import { DocumentReference, Timestamp } from 'firebase/firestore';
import { FirestoreDocument } from './generalModels';
import { MetaData, OrganizationPermissions, OrganizationRole } from '@repo/shared';

export const inviteLinkIdPrefix = 'inviteLink_';

export const inviteCollectionsToIdPrefixMapping: { [key: string]: string } = {
	inviteLinks: inviteLinkIdPrefix,
};

export type InviteLink5 = FirestoreDocument & {
	organizationPointer: { ref: DocumentReference; id: string };
	inviteCode: string;
	expires: Timestamp;
	remainingUses: number;
	created: MetaData;
	role: OrganizationRole; // setting roles (except 'custom') should automatically set permissions
	permissions: OrganizationPermissions;
};

export type UseInviteLinkBody5 = {
	inviteId: string;
};

export type UserInviteLinkFunctionResult5 = {
	success: boolean;
	alreadyMember: boolean;
	localization: string;
};
