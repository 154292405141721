export enum Permission {
	PUBLIC = 'public',
	ORGANIZATION = 'organization',
	PRIVATE = 'private',
}

export const PERMISSION_LEVELS = {
	[Permission.PUBLIC]: 1,
	[Permission.ORGANIZATION]: 2,
	[Permission.PRIVATE]: 3,
} as const;

export const toPermissionLevel = (permission: Permission) => {
	return PERMISSION_LEVELS[permission];
};
