import { CRUD_Rights, R_Rights, RU_Rights, RUD_Rights } from '../http';
import {
	ORGANIZATION_CREATOR_PERMISSIONS,
	ORGANIZATION_GUEST_PERMISSIONS,
	ORGANIZATION_MANAGER_PERMISSIONS,
	ORGANIZATION_OWNER_PERMISSIONS,
	ORGANIZATION_PUBLIC_PERMISSIONS,
	ORGANIZATION_USER_PERMISSIONS,
} from './permissions';

export enum OrganizationRole {
	OWNER = 'owner',
	MANAGER = 'manager',
	CREATOR = 'creator',
	USER = 'user',
	GUEST = 'guest',
	PUBLIC = 'public',

	CUSTOM = 'custom',
}

export type OrganizationPermissions = {
	organization: {
		info: RUD_Rights;
		variables: RUD_Rights;
	};
	memberships: {
		members: RUD_Rights;
		permissions: RU_Rights;
		invites: CRUD_Rights;
		inviteLinks: CRUD_Rights;
		publicTokens: CRUD_Rights;
	};
	administration: {
		creditHistory: R_Rights;
		credits: R_Rights;
	};
	mvp1: {
		accessTokens: CRUD_Rights;
		templates: CRUD_Rights;
		chats: CRUD_Rights; // note: deleting your own chats is always allowed
	};
};

/// utility functions

export function getRoleValue(role: OrganizationRole): number {
	switch (role) {
		case OrganizationRole.OWNER:
			return 4;
		case OrganizationRole.MANAGER:
			return 3;
		case OrganizationRole.CREATOR:
			return 2;
		case OrganizationRole.USER:
			return 1;
		case OrganizationRole.GUEST:
			return 0;
		case OrganizationRole.PUBLIC:
			return -1;
		default:
			return -2;
	}
}

export function isRoleAtLeast(role: OrganizationRole, atLeast: OrganizationRole): boolean {
	return getRoleValue(role) >= getRoleValue(atLeast);
}

export function getPermissionsForRole(role: OrganizationRole): OrganizationPermissions {
	switch (role) {
		case OrganizationRole.OWNER:
			return ORGANIZATION_OWNER_PERMISSIONS;
		case OrganizationRole.MANAGER:
			return ORGANIZATION_MANAGER_PERMISSIONS;
		case OrganizationRole.CREATOR:
			return ORGANIZATION_CREATOR_PERMISSIONS;
		case OrganizationRole.USER:
			return ORGANIZATION_USER_PERMISSIONS;
		case OrganizationRole.GUEST:
			return ORGANIZATION_GUEST_PERMISSIONS;
		case OrganizationRole.PUBLIC:
			return ORGANIZATION_PUBLIC_PERMISSIONS;
		default:
			return ORGANIZATION_PUBLIC_PERMISSIONS;
	}
}
