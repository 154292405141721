import { IdTag } from './common';
import { Condition } from './conditions';

export type FormInputUid = IdTag<'formInputUid'>;

export enum FormComponentType {
	If = 'if',
	EndIf = 'endif',
	Text = 'text', // just text, no input
	Button = 'button', // todo
	Prompt = 'prompt', // a prompt piece of text, no input, can be used to encapsulate with conditions

	Webhook = 'webhook',

	Textfield = 'textfield',
	Textarea = 'textarea',
	Checkbox = 'checkbox',
	Toggle = 'toggle', // todo
	Url = 'url', // todo
	Image = 'image', // todo
	Radio = 'radio',
	Dropdown = 'dropdown',
	Pick = 'pick',
	RichText = 'richtext',
}

export type FormComponent = {
	index: number; // The index of the form component in the text
	type: FormComponentType; // The type of the form component
};

export type FormIfComponent = FormComponent & {
	type: FormComponentType.If | FormComponentType.EndIf;
};

export type FormConditionalComponent = FormComponent & {
	condition: Condition; // The conditions that must be met for the form component to be shown/used
};

export type FormPromptComponent = FormConditionalComponent & {
	type: FormComponentType.Prompt;
	value: string; // The value of the prompt, should be emptied when extracted as a form input component!
};
